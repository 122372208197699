<template>
    <layout-default>
        <section class="hero hero-bright bg-cover bg-top"
            v-bind:style="{ 'background-image': 'url(' + require('../assets/img/ueber.jpg') + ')' }">
            <div class="container z-index-20 mt-lg-5">
                <div class="row align-items-lg-center gy-4">
                    <div class="col-lg-7 order-2 order-lg-1">
                        <h1 class="text-uppercase text-white">Über <span class="text-success">uns</span></h1>
                        <p class="lead text-white mb-0">Die beste Gesundheitsversorgung in Ihrer Region</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- About Section-->
        <section class="about bg-light">
            <div class="container">
                <div class="row gy-5">
                    <div class="col-lg-4">
                        <div class="card border-0">
                            <div class="card-body p-0 shadow-sm d-flex flex-column justify-content-center text-center">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/ralph.jpg"
                                        alt="..."></div>
                                <div class="p-4">
                                    <h6 class="mb-0">Ralph</h6>
                                    <p class="text-muted text-sm mb-0">Inhaber</p>
                                </div>
                                <div class="card-back">
                                    <h4>
                                        🦸‍♀️ Therapeuten 🦸‍♂️ sind hartnäckiger als 🤕 Beschwerden!
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <p class="text-muted h6 text-primary text-uppercase mb-0">Dafür stehen wir</p>
                        <h2 class="my-3 h5">Erstklassige medizinische Versorgung. Der Mensch im Mittelpunkt. </h2>
                        <i18n path="about.we_are_looking_forward" tag="p" class="text-muted">
                            <br />
                            <br />
                        </i18n>
                        <div class="p-2 bg-primary">
                            <div class="border border-white border-2 p-lg-5 p-4 d-flex align-items-center">
                                <i class="fas fa-comment-quote fa-3x text-white"></i>
                                <div class="ps-3">
                                    <h4 class="text-white mb-1">Unsere Herzensangelegenheit</h4>
                                    <p class="text-white">
                                        Wir wissen: Oft benötigen die kleinen und großen Patienten nur einen Anstoß,
                                        manchmal aber auch eine intensive Therapie, um Erfolge oder das gesetzte Ziel zu
                                        erreichen.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Statistics Section-->
        <section class="statistics py-5 bg-primary">
            <div class="container text-white">
                <div class="row gy-4">
                    <div class="col-lg-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg me-3 mb-0">1</h2>
                            <p class="lead lh-sm mb-0">Motiviertes<br />Team</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg me-3 mb-0">50+</h2>
                            <p class="lead lh-sm mb-0">Jahre<br />Erfahrung</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg me-3 mb-0">70+</h2>
                            <p class="lead lh-sm mb-0">Fortbildungen <br /> und Zertifikate</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                            <h2 class="text-lg me-3 mb-0">1000+</h2>
                            <p class="lead lh-sm mb-0">Glückliche<br />Patienten</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-light">
            <div class="container text-center">
                <h2 class="text-uppercase lined lined-center">Unser <span class="text-primary">Team</span></h2>
                <p class="text-muted mb-5">Wir sind für DICH da</p>
                <div class="row gy-4">
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/carolin.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Carolin</h6>
                                    <p class="text-muted text-sm mb-0">Frontoffice</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie hat hier den Plan: „🤍💙💚“</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/helena.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Helena</h6>
                                    <p class="text-muted text-sm mb-0">Buchhaltung</p>
                                </div>
                                <div class="card-back">
                                    <h4>Engel 😇 sieht man nicht, doch sind sie 😶‍🌫️ da!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/stefanie.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Stefanie</h6>
                                    <p class="text-muted text-sm mb-0">Backoffice</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie is(s)t 🍭 Zucker 🍩 süß und 🤓 raffiniert</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/anne.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Anne</h6>
                                    <p class="text-muted text-sm mb-0">Frontoffice / Marketing</p>
                                </div>
                                <div class="card-back">
                                    <h4>Wer lernt 👩‍🎓 hier von 🤓 wem?</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/christian.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Christian</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>Er hat den absoluten 😎 Durchblick!✌</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/alexander.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Alexander</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>Mit viel Charme 😉 von Fuß bis über 🙋‍♂️ Schulter Arm!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/corina.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Corina</h6>
                                    <p class="text-muted text-sm mb-0">Ergotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>🧠 Die Queen des Neurofeedback 💪 kennt dein Potential!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative"><img class="img-fluid" src="../assets/img/marita.jpg"
                                        alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Marita</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>🤣 Ihr Strahlen 🌞 reißt jeden mit!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/maria.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Maria</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Wow die Frau 👩 hat 👩‍🎓 Know-how!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/dominik.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Dominik</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeut</p>
                                </div>
                                <div class="card-back">
                                    <h4>💡Unser Mann für Evidenz und Osteopathie Expertise 📈</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/annika.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Annika</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Charmant 😉 versprüht, sie frischen Wind 🌬️</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/keisha.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Keisha</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Ob jung 👧 ob alt ihr lächeln 😀 lässt niemand kalt!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Marie ab 1.09.2024 aktivieren
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/marie.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Marie</h6>
                                    <p class="text-muted text-sm mb-0">Ergotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4> 👩🎨 Bunter und vielfältiger als ein Regenbogen🌈</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/anna.jpg" alt="...">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Anna</h6>
                                    <p class="text-muted text-sm mb-0">Physiotherapeutin</p>
                                </div>
                                <div class="card-back">
                                    <h4>Sie bring „🤩“ ins Leben!</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <div class="card doctor border-0">
                            <div class="card-body p-0 shadow-sm">
                                <div class="position-relative">
                                    <img class="img-fluid" src="../assets/img/blanco.jpg" alt="blanco Bild">
                                </div>
                                <div class="p-4">
                                    <h6 class="mb-0">Wird dies dein Name?</h6>
                                    <p class="text-muted text-sm mb-0">Dein Abenteuer wartet</p>
                                </div>
                                <div class="card-back">
                                    <h4>😃 lass dich überraschen 😇</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--
        <section class="">
            <div class="container">
                <div class="text-center">
                    <h2 class="text-uppercase lined lined-center"><span class="text-primary">Wir...</span></h2>
                </div>
                <div class="row gy-4 pt-4">
                    <div class="col-lg-6">
                        <ul class="list-check list-unstyled row px-3 mb-4 gy-2">
                            <li class="text-muted"><i class="fas fa-circle-check"></i> arbeiten in erfahrenen, interdisziplinären Teams</li>
                            <li class="text-muted"><i class="fas fa-circle-check"></i> nehmen uns Zeit für Sie und hören genau zu</li>
                            <li class="text-muted"><i class="fas fa-circle-check"></i> arbeiten mit modernsten Therapiemitteln</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <ul class="list-check list-unstyled row px-3 mb-4 gy-2">
                            <li class="text-muted"><i class="fas fa-circle-check"></i> haben barrierefreie Zugänge</li>
                            <li class="text-muted"><i class="fas fa-circle-check"></i> betreuen Sie auch in Ihrem Zuhause oder einem Pflegeheim</li>
                            <li class="text-muted"><i class="fas fa-circle-check"></i> freuen uns auf Sie!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        -->
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

export default {
    name: 'About',
    components: {
        LayoutDefault
    }
};
</script>

<style scoped>
.list-check li {
    padding-left: 1.5rem;
    position: relative;
}

.list-check li i {
    color: #084765;
}

.hero {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.card {
    perspective: 1000px;
}

.card:hover .card-body {
    transform: rotateY(180deg);
}

.card-body {
    transform-style: preserve-3d;
    transition: transform 0.5s;
}

.card-front,
.card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.card-front {
    z-index: 2;
    transform: rotateY(0deg);
}

.card-back {
    transform: rotateY(180deg);
    background-color: #084765;
    /* #f0f0f0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    z-index: 1;
    /* Den Text auf der Rückseite sichtbar machen */
}

.img-fluid {
    max-width: 100%;
    height: auto;
}
</style>
